import { Controller } from '../utils/controller';

interface ILoading {
  element: Element | null | undefined;
  state: boolean;
}

interface IUpdateQuantityParams {
  newQty: number;
  disabled?: boolean;
}

interface ICollection {
  cartId: string;
  collectionId: string;
  collectionName: string;
  collectionType: string;
  collectionSubtype: string;
}

interface IRequestParams {
  action?: 'delete' | 'update';
  collectionData?: ICollection;
  offerCode?: string;
  quantity?: number;
  skus?: string | string[];
  isReward?: boolean;
}

interface IHandleOnHoverMessage {
  messageSelector: string;
  shouldUpdate: boolean;
}

export default class extends Controller {
  static values = {
    cartLimit: Boolean,
    collection: Object,
    extraItems: Number,
    isOutOfStock: Boolean,
    isReward: Boolean,
    multiLast: Boolean,
    offerCode: String,
    qty: Number,
    qtyLimit: Number,
    skuId: String,
    skuName: String,
    thumbnailAlternatives: Array,
    type: String,
    dataEndpoint: '/cart/v3_0/drawer/'
  };

  declare cartLimitValue: boolean;
  declare collectionValue: ICollection;
  declare isOutOfStockValue: boolean;
  declare isRewardValue: boolean;
  declare dataEndpointValue: string;
  declare extraItemsValue: number;
  declare multiLastValue: boolean;
  declare offerCodeValue: string;
  declare qtyValue: number;
  declare qtyLimitValue: number;
  declare skuIdValue: string;
  declare skuNameValue: string;
  declare typeValue: string;

  connect(): void {
    this.setDataEndpointFromParent();
    this.handleImageLoading();
    this.handleQtyBtnState();

    if (this.multiLastValue) {
      this.handleBundledItems();
    }
  }

  setDataEndpointFromParent() {
    const cartItemsList = this.element?.parentElement;

    if (cartItemsList) {
      const cartDrawer = cartItemsList.parentElement;
      const dataEndpoint = cartDrawer?.getAttribute('data-cart-drawer-data-endpoint-value');
      if (!!dataEndpoint && dataEndpoint !== this.dataEndpointValue) {
        this.dataEndpointValue = dataEndpoint;
      }
    }
  }

  checkLimit(): void {
    this.handleOnHoverMessages(
      [this.quantityLimitElement, this.quantityOOSElement, this.orderQuantityLimitElement],
      'remove'
    );
  }

  hideLimit(): void {
    this.handleOnHoverMessages(
      [this.quantityLimitElement, this.quantityOOSElement, this.orderQuantityLimitElement],
      'add'
    );
  }

  private handleOnHoverMessages(elements: IHandleOnHoverMessage[], method: 'add' | 'remove'): void {
    elements.forEach(({ messageSelector, shouldUpdate }) => {
      const element = this.element?.shadowRoot?.querySelector(messageSelector);

      if (element && shouldUpdate) {
        element.classList[method]('hidden');
      }
    });
  }

  private handleBundledItems(): void {
    const bundledExtraItems = document.createElement('div');
    bundledExtraItems.classList.add('cart-item__bundled-extra');
    bundledExtraItems.slot = 'bundled';
    bundledExtraItems.textContent = `+${this.extraItemsValue}`;

    this.element.appendChild(bundledExtraItems);
  }

  private loading(element: ILoading['element'], state: ILoading['state']): void {
    element?.classList[state ? 'add' : 'remove']('loading');
  }

  async updateQuantity({ params: { newQty, disabled } }: { params: IUpdateQuantityParams }) {
    if (disabled) {
      return;
    }

    const requestBody = this.buildUpdateRequest(newQty);
    const cartItemElement = this.element?.shadowRoot?.querySelector('.cart-item-container');

    this.loading(cartItemElement, true);

    fetch(this.dataEndpointValue, requestBody)
      .then(async (response) => {
        try {
          const htmlCartResponse = await response.text();
          const action = newQty === 0 ? 'remove' : newQty > this.qtyValue ? 'increase' : 'decrease';

          const detail = {
            action,
            newQty,
            updatedSkuId: this.skuIdValue,
            updatedSkuName: this.skuNameValue
          };

          if (this.validateHTMLResponse(htmlCartResponse)) {
            detail['html'] = htmlCartResponse;
          }

          this.qtyValue = newQty;
          const event = new CustomEvent('fetchCartDrawer', { detail });
          window.dispatchEvent(event);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Could not dispatch event', error);
          this.loading(cartItemElement, false);
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Could not update item', this.skuIdValue, 'reason', error);
        this.loading(cartItemElement, false);
      });
  }

  async removeItem() {
    await this.updateQuantity({ params: { newQty: 0 } });
  }

  private buildUpdateRequest(newQty: number) {
    const isOfferItem = this.offerCodeValue !== '';
    const isCollectionItem = Object.entries(this.collectionValue).length > 0;
    const action = newQty === 0 ? 'delete' : 'update';

    const requestParams: IRequestParams = isOfferItem
      ? { offerCode: this.offerCodeValue }
      : { action, quantity: newQty, skus: [this.skuIdValue], isReward: !!this.isRewardValue };

    if (isCollectionItem) {
      requestParams.skus = this.skuIdValue;
      requestParams['collectionData'] = this.collectionValue;
    }

    return {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-csrf-token': this.csrfToken
      },
      body: JSON.stringify(requestParams)
    };
  }

  private validateHTMLResponse(html: string): boolean {
    try {
      const parsedResponse = JSON.parse(html);

      if (parsedResponse && 'sku' in parsedResponse) {
        return false;
      }
    } catch {
      return true;
    }

    return false;
  }

  private handleImageLoading(): void {
    const images = this.element?.querySelectorAll('img');
    if (images?.length > 0) {
      images.forEach((image) => {
        image.addEventListener('load', () => {
          image.classList.add('loaded');
        });
        image.addEventListener('error', this.checkImage.bind(this, image));
      });
    }
  }

  private handleQtyBtnState(): void {
    const className = '.cart-item__quantity-button';
    const increaseButton = this.element?.shadowRoot?.querySelector(`${className}--up`);
    const decreaseButton = this.element?.shadowRoot?.querySelector(`${className}--down`);

    if (this.increaseDisabled && increaseButton) {
      increaseButton.ariaDisabled = 'true';
      increaseButton.classList.add('disabled');
    }

    if (this.decreaseDisabled && decreaseButton) {
      decreaseButton.ariaDisabled = 'true';
      decreaseButton.classList.add('disabled');
    }
  }

  private checkImage(element): void {
    element.classList.remove('loaded');
    const alternatives = JSON.parse(
      element?.getAttribute('data-cart-item-thumbnail-alternatives-value') || '[]'
    );
    if (alternatives.length > 0) {
      const alternative = alternatives.shift();
      if (alternative) {
        element.src = alternative;
        element.setAttribute(
          'data-cart-item-thumbnail-alternatives-value',
          JSON.stringify(alternatives)
        );
      }
    }
  }

  private get increaseDisabled(): boolean {
    return this.cartLimitValue || this.qtyValue === this.qtyLimitValue || this.isOutOfStockValue;
  }

  private get decreaseDisabled(): boolean {
    return this.qtyValue === 1;
  }

  private get quantityLimitElement() {
    return {
      messageSelector: '.cart-item-quantity-limit-wrapper',
      shouldUpdate: this.qtyValue === this.qtyLimitValue
    };
  }

  private get quantityOOSElement() {
    return {
      messageSelector: '.cart-item-quantity-oos-wrapper',
      shouldUpdate: this.isOutOfStockValue
    };
  }

  private get orderQuantityLimitElement() {
    return {
      messageSelector: '.cart-item-order-qty-limit-wrapper',
      shouldUpdate: this.cartLimitValue
    };
  }
}
