/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
  var drupalSkuUpdate = Drupal.settings.analytics ? Drupal.settings.analytics.sku_product_update : false;

  Drupal.behaviors.analyticsBehavior = {
    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    trackHomePageEvents: function() {
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var position = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          position.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return position;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      var analyticsSettings = Drupal.settings.analytics ? Drupal.settings.analytics.analytics_tagging_enabled : false;
      var hairQuizAnalyticsSettings = Drupal.settings.analytics ? Drupal.settings.analytics.hair_quiz_tagging_enabled : false;
      var eventObj = {};

      // Event track common function call start.
      function trackEvent(eName, eCategory, eAction, eLabel) {
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: eLabel
        });
        site.track.evtLink(eventObj);
        eventObj = {};
      }
      // Event track common function call end.

      if (self.attached) {
        return;
      }

      // Track Home Page Events
      if (location.pathname === '/') {
        self.trackHomePageEvents();
      }

      // Track Brand Logo
      $('a.site-header__menu-logo,a.site-header__utility-logo', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Product Click in quickshop
      $(document).on('click', '.js-spp-link, .js-quickshop-launch-inline, .js-product-brief-subline a', function () {
        var $currentElem = $(this);
        var $prodElem = $currentElem.closest('.js-product');
        var prodId = $prodElem.attr('data-product-id');
        var $prodItemElem = $currentElem.closest('.js-product-grid-item, .esearch-product');
        var prodPosition = 0;
        var prodName = $prodItemElem.find('.product-brief__name, .esearch-product__headline').text().trim();
        var $productPriceElem = $prodItemElem.find('.js-sale-price, .js-sku-price').first().text().trim();
        var prodPrice = Number($productPriceElem.replace(/[^0-9\.]/gi, ''));

        $('.js-product-grid-content .js-product-grid-item, .esearch-product, .js-product-grid .js-product-grid-item').each(function(index) {
          $(this).attr('data-index', index);
        });

        prodPosition = Number($prodItemElem.data('index')) + 1;

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_price: [prodPrice],
            product_impression_name:[prodName]
          });
        }
      });

      // Product click on Recommendation section
      recomProductSelectors = [
        '.on-the-go-product-section__products-list--items .product-img a',
        '.on-the-go-product-section__products-list--items .product-name a',
        '.recommended-item a.recommended-item__button',
        '.recommended-item .product-name a',
        '.recommended-item a.thumb',
        '.recommended-item .product_name a',
        '.recommended-item .produce_subname a',
        '.on-the-go-item .product-img a',
        '.on-the-go-item .product-name a'
      ];
      $(document).on('click', recomProductSelectors.join(', '), function () {
        $('.recommended-product-items .recommended-item, .on-the-go-content__products-list .on-the-go-item').each(function(index) {
          $(this).attr('data-index', index);
        });
        var $prodElem = $(this);
        var prodId = $prodElem.closest('.on-the-go-product-section__products-list--items').data('product-id');
        if (typeof prodId == 'undefined') {
          var $prodHref = $prodElem.attr('href');
          var splitprod = $prodHref.split('/');
          if (splitprod[3] === 'product') {
            var prodId = 'PROD' + splitprod[5];
          } else {
            var prodId = 'PROD' + splitprod[3];
          }
        }
        var prodPosition = Number($prodElem.closest('.recommended-item, .on-the-go-item').data('index')) + 1;
        var prodName = $prodElem.closest('.recommended-item, .on-the-go-item').find('.product_name, .product-name').text().trim();
        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $prodElem,
            product_id: [prodId],
            product_position: [prodPosition],
            product_impression_name:[prodName]
          });
        }
      });

      //  Track Notify me button
      $('.js-add-to-waitlist').on('click', function() {
        var $targetElem = $(this).closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h2.product-full__name:first').text().trim();

        site.track.evtLink({
          event_name: 'notify_me',
          event_action: 'notify me - request',
          event_category: 'ecommerce',
          event_label: prodName + ' - ' + prodId
        });
      });

      $(document).on('click', '.product-quickshop__add-to-bag a.js-add-to-waitlist', function() {
        var $targetElem = $(this).closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('h1.product-full__name:first').text().trim();
        trackEvent('notify_me', 'ecommerce', 'notify me - request', prodName + ' - ' + prodId);
      });

      // Track Quick Shop
      $(document).on('click', '.js-quickshop-launch-inline', function () {
        var obj;
        var $targetElem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElem.closest('.js-product-grid-item');
        var prodId = $targetElem.attr('data-product-id');
        var prodName = self.stripOutMarkup(prodElem.find('.js-product-brief').find('.product-name a').html());

        obj = {
          event_label: [prodId],
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()]
        };
        var skuId = $targetElem.siblings('.product-brief__favorites').find('.js-add-to-favorites').attr('data-sku-base-id');
        if (drupalSkuUpdate && skuId) {
          var skuData = prodcat.data.getSku(skuId);

          if (skuData.SHADENAME !== '' && skuData.SHADENAME !== null) {
            obj.product_shade = [skuData.SHADENAME];
          }
          if (skuData.PRODUCT_SIZE !== '' && skuData.PRODUCT_SIZE !== null) {
            obj.product_size = [skuData.PRODUCT_SIZE];
          }
          obj.product_price = [skuData.PRICE.toString()];
          obj.product_product_code = [skuData.PRODUCT_CODE];
          obj.product_sku = [skuId];
        }
        site.track.quickView(obj);

      });

      // Track Predictive Search Product Click and All Results Click
      $('.js-typeahead-wrapper').on('click', '.result', function() {
        var $prod = $(this);
        var obj;
        var $prodWrapper = $prod.find('.js-product');
        var term = $('input#nav-search').val();
        self.setLinkCapture($prodWrapper.attr('href'), '_self');
        obj = {
          event_label: term,
          search_keyword: term,
          product_id: $prodWrapper.attr('data-product-id'),
          product_name: self.stripOutMarkup($prodWrapper.find('h3 a').text())
        };
        site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
      });

      // Track MPP Filters
      $('.js-mpp-sort-menu, .js-track-content-block-filter-dropdown', context).change(function () {
        var elem = $('option:selected', this).text();
        var obj = {
          event_label: elem
        };
        site.track.evtAction('filterProducts', obj);
      });

      //Mpp Filter Best seller
      $('a.js-filter-trigger', context).once().on('click', function() {
        var obj = {
          event_label: $(this).text().trim()
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS
      // Track guest user checkout
      $(document).on('click', '#checkout_signin_new_user-submit, #checkout_signin_guest_user-submit, .js-analytics-new-user-input-submit, #new-user-input-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $(document).on('click', '#checkout_signin-submit, #js-analytics-return-user-input-submit, .js-checkout_signin_return_user-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track Payment Method
      var paymentElements = [
        '#checkout_billing input.js-submit-payment',
        '#checkout_review input.place-order',
        '#continue-checkout-btm input#continue-to-payment-btn',
        '#checkout_complete input#continue-to-payment-btn',
        '.js-analytics-payment',
        '#checkout_payment input.js-submit-payment',
        '#checkout_complete input.js-submit-payment'
      ];
      $(document).on('click', paymentElements.join(', '), function() {
        var paymentLabel = '';
        var paymentTypePP = [
          'PayPal',
          'PP',
          'PAYMENT_OPTION_PAYPAL'
        ];
        var paymentTypeCC = [
          'Credit Card',
          'CC',
          'PAYMENT_OPTION_MC',
          'PAYMENT_OPTION_VISA',
          'PAYMENT_OPTION_AMEX',
          'PAYMENT_OPTION_SOFORT',
          'GMO',
          'SecurePay',
          'HPP',
          'VBank'
        ];
        var paymentTypeCOD = [
          'COD'
        ];
        var paymentType = $('input[name=PAYMENT_METHOD]:checked').val() || $('input[name=PP_NAME]:checked').val() || $('input[name$=Payment_Method]:checked').val() || $('input[name$=Payment_Type]:checked').val() || $('input[name$=PAYMENT_OPTION]:checked').val();
        if ($.inArray(paymentType, paymentTypePP) > -1) {
          paymentLabel = 'paypal';
        } else if ($.inArray(paymentType, paymentTypeCC) > -1) {
          paymentLabel = 'creditcard';
        } else if ($.inArray(paymentType, paymentTypeCOD) > -1) {
          paymentLabel = 'cashondelivery';
        } else {
          paymentLabel = 'AfterPay';
        }
        var obj = {
          event_label: paymentLabel
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // END CHECKOUT EVENTS

      // Refer-A-Friend event Tagging start
      if ($('body').hasClass('section-refer-a-friend') && analyticsSettings) {
        var pageUrl = document.location.href;
        var token = '';
        var referMethod = '';

        $(document).on('submit', '.extole-js-email-panel-email-share-form', function() {
          var userEmail = '';
          var referEmail = '';
          var $formElem = $(this);
          userEmail = $formElem.find('.extole-user-email .extole-tokenizer-token').text();
          referEmail = $formElem.find('.extole-recipient-emails .extole-tokenizer-token').text();
          referMethod = $('.extole-panel-link--active').attr('aria-label');
          if (userEmail && referEmail) {
            trackEvent('refer a friend', 'refer_friend', 'refer triggered', 'refer_' + referMethod + ' - ' + pageUrl);
          }
        });

        var extoleConfirm = [
          '.extole-js-facebook-panel-capture-email-form',
          '.extole-js-twitter-panel-capture-email-form',
          '.extole-js-messenger-panel-capture-email-form',
          '.extole-js-pinterest-panel-capture-email-form'
        ];

        $(document).on('submit', extoleConfirm.join(', '), function() {
          referMethod = $('.extole-panel-link--active').attr('aria-label');
          token = $(this).find('.extole-tokenizer-token').text();
          if (token) {
            trackEvent('refer a friend', 'refer_friend', 'refer triggered', 'refer_' + referMethod + ' - ' + pageUrl);
          }
        });

        var extoleDefault = [
          '.extole-js-twitter-panel-share-form',
          '.extole-js-facebook-panel-share-form',
          '.extole-js-messenger-panel-share-form',
          '.extole-js-pinterest-panel-share-form'
        ];

        $(document).on('submit', extoleDefault.join(', '), function() {
          referMethod = $('.extole-panel-link--active').attr('aria-label');
          token = $('.extole-js-email-panel-email-share-form').find('.extole-tokenizer-token').text();
          if (token) {
            trackEvent('refer a friend', 'refer_friend', 'refer triggered', 'refer_' + referMethod + ' - ' + pageUrl);
          }
        });
      }
      // Refer-A-Friend event Tagging end

      // My Appointment page tracking
      $(document).on('click', '.js-appt-book-btn', function () {
        trackEvent('appoinment booking', 'appoinment booking', 'my appointments page click', 'book now');
      });

      $(document).on('click', '.js-change-appt-btn', function () {
        trackEvent('appoinment booking', 'appoinment booking', 'my appointments page click', 'change appoinment');
      });

      $(document).on('click', '.js-cancel-appt-btn', function () {
        trackEvent('appoinment booking', 'appoinment booking', 'my appointments page click', 'cancel appoinment');
      });

      $(document).on('click', '.js-appt-date-cal', function () {
        trackEvent('appoinment booking', 'appoinment booking', 'my appointments page click', 'add calendar');
      });

      $(document).on('click', '.js-location-direction-links', function () {
        trackEvent('appoinment booking', 'appoinment booking', 'my appointments page click', 'get direction');
      });
      // My appointment page tracking ends

      // Hair Quize tagging start.
      if (hairQuizAnalyticsSettings && ($('body').hasClass('section-hair-quiz') || $('body').hasClass('hair-quiz-page'))) {
        trackHairQuizTagging();
      }

      function trackHairQuizTagging() {
        initHairQuizTagging();

        function trackStartQuiz() {
          $(document).on('click', '.js-mantle-cta-button', function() {
            var $quizElem = $(this);
            var eventAction = $quizElem.text().trim();
            var $hairQuizParentElem = $quizElem.closest('.form-formatter__item');
            var hairQuizIntro = $hairQuizParentElem.data('field-name');
            var hairIntroStep = $hairQuizParentElem.data('skip-form-state');
            var hairQuizQuestionNum = $hairQuizParentElem.data('field-order');
            var hairQuizQuestion = $hairQuizParentElem.find('.mantle-form-options-field__subheader').find('p').text().trim();
            var hairQuizAnswer = $hairQuizParentElem.find('.js-mantle-form__checkbox.active').find('.label-content').text().trim();
            var hairQuizTrigger = $quizElem.data('trigger-type');
            var hairpageloadStep = hairQuizQuestionNum + 1;
            if (hairQuizIntro === 'intro') {
              trackEvent('diagtools', 'diagnostic tool - hair quiz', eventAction, 'click');
              trackHairQuizStepPage(hairIntroStep);
            } else if (hairQuizTrigger === 'back') {
              eventObj.page_url = location.pathname + '/step_' + hairQuizQuestionNum;
              eventObj.location = location.origin + location.pathname + '/step_' + hairQuizQuestionNum;
              eventObj.page_name = 'Hair Quiz -Step ' + hairQuizQuestionNum;
              trackEvent('diagtools', 'diagnostic tool - hair quiz', 'backbutton_step' + hairQuizQuestionNum, 'click');
              trackHairQuizStepPage(hairQuizQuestionNum - 1);
            } else if (hairQuizTrigger === 'submit' && hairQuizAnswer) {
              trackEvent('diagtools' + hairpageloadStep, 'diagnostic tool - hair quiz', 'questions - ' + hairQuizQuestionNum, hairQuizQuestion + ' - ' + hairQuizAnswer);
            } else {
              if (hairQuizAnswer) {
                eventObj.page_url = location.pathname + '/step_' + hairQuizQuestionNum;
                eventObj.location = location.origin + location.pathname + '/step_' + hairQuizQuestionNum;
                eventObj.page_name = 'Hair Quiz -Step ' + hairQuizQuestionNum;
                trackEvent('diagtools', 'diagnostic tool - hair quiz', 'questions - ' + hairQuizQuestionNum, hairQuizQuestion + ' - ' + hairQuizAnswer);
                trackHairQuizStepPage(hairpageloadStep);
              }
            }
          });
        }

        $(document).on('hairQuizResult', function() {
          var $hairQuizItems = $('.js-quiz-landing-page-formatter__results');
          var productImpressionIds = [];
          var productPositions = [];
          var productImpressionLists = [];
          var $elem;
          $hairQuizItems.find('.js-quickshop').each(function(index, elem) {
            if (!$(elem).length) {
              return;
            }
            $elem = $(elem);
            productImpressionIds.push($elem.data('product-id'));
            productImpressionLists.push(location.pathname + '/results');
            productPositions.push(index + 1);
            $(this).attr('data-position', index + 1);
          });

          var objView = {
            page_url: location.pathname + '/results',
            location: location.origin + location.pathname + '/results',
            page_name: 'Hair Quiz - Complete',
            product_impression_list: productImpressionLists,
            product_impression_position: productPositions,
            product_impression_id: productImpressionIds
          };
          site.track.evtView(objView);
        });

        function trackQuickShop() {
          $(document).on('click', '.quiz-product-card.js-quickshop', function() {
            var $quizShopElem = $(this);
            var quizResultProdID = $quizShopElem.data('product-id');
            var quizResultPostion = $quizShopElem.data('position');
            eventObj.enh_action = 'product_click';
            eventObj.product_position = [quizResultPostion];
            eventObj.product_list = [location.pathname + '/results'];
            eventObj.product_id = [quizResultProdID];
            trackEvent('diagtools', 'ecommerce', 'product click', 'product click');
          });
        }

        function trackAddtoBag() {
          $(document).on('click', '.quiz-product-card .js-add-to-cart', function() {
            var addToBagProdID = $(this).data('product-id');
            site.track.evtView({
              enh_action: 'detail',
              product_id: [addToBagProdID]
            });
          });
        }

        function trackHairQuizRetake() {
          $(document).on('click', '.retake-quiz', function() {
            var eventAction = $(this).text().trim();
            trackEvent('avedaQuiz_Restart', 'diagnostic tool - hair quiz', eventAction, 'click');
          });

          $(document).on('hairRequiz', function() {
            trackEvent('avedaQuiz_Restart', 'diagnostic tool - hair quiz', 'Retake Quiz', 'click');
          });
        }

        function trackHairQuizStepPage(quizStep) {
          var objView = {
            page_url: location.pathname + '/step_' + quizStep,
            location: location.origin + location.pathname + '/step_' + quizStep,
            page_name: 'Hair Quiz -Step ' + quizStep
          };
          site.track.evtView(objView);
        }

        function initHairQuizTagging() {
          trackStartQuiz();
          trackQuickShop();
          trackAddtoBag();
          trackHairQuizRetake();
        }
      }

      // Hair Quize tagging end.

      // Look toggle tagging start.
      if ($('ul').hasClass('mpp-tab__list')) {
        $(document).on('click', '.js-tab-title', function() {
          var eventLabel = $(this).text().trim();
          trackEvent('looks toggle', 'ecommerce', 'product toggle click', eventLabel);
        });
      }
      // Look toggle tagging end.

      // Hair & Scalp Check "Get Personal" Button - Start
      if ($('body').hasClass('section-hair-quiz') || $('body').hasClass('hair-quiz-page')) {
        $(document).on('click', '.js-analytics-hair-quiz-link a', function() {
          trackEvent('diagtools', 'diagnostic tool - hair quiz', 'start button', 'click');
        });
      }
      // Hair & Scalp Check "Get Personal" Button - End

      // Search page filter tagging start.
      if ($('div').hasClass('js-search-wrapper__filters')) {
        $(document).on('search-filter', function () {
          var searchFilterArray = [];
          var eventSplitFlag = $('div').hasClass('js-filter-remove-search');
          $('.js-breadcrumbs__link').each(function () {
            var searchSplit = $(this).text().trim().split('(');
            searchSplit = searchSplit[0];
            searchFilterArray.push(searchSplit);
          });
          var eventLabel = searchFilterArray.join("|");
          if (eventLabel && !eventSplitFlag) {
            trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - search', eventLabel);
          }
          $('.js-end-results-breadcrumbs').removeClass('js-filter-remove-search');
        });

        $('a.js-mpp-filter-set__reset').on('click', function () {
          trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - search', 'Clear All');
        });
      }
      // Search page filter tagging end.

      // Cart page tagging start - Tracking gift wrap
      $(document).on('click', '.js-save-gift-options', function () {
        var data = site.track.refreshData() || {};
        trackEvent('gift wrap', 'ecommerce', 'gift wrap - cart', data.cart_product_id);
      });
      
      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-viewcart-samples-products, .gift-options-content__header, .donation-section__header, .pure-privilege-section__header, .on-the-go-product-section__header, .js-carbon-footprint-panel, .js-past-purchases-products', function () {
        var clickSense = $(this).find('.icon--plus').is(':visible'),
        classer = $(this).attr('class') ? $(this).attr('class') : '';
        var evtLabel = classer.indexOf('carbon-footprint') > -1 ? $(this).find('.carbon-footprint__heading-text').text().trim() : $(this).text().trim();
        if (classer.indexOf('carbon-footprint') > -1) {
          evtLabel = $(this).find('.carbon-footprint__heading-text').text().trim();
        }
        else if (classer.indexOf('eligible_boutique') > -1) {
          evtLabel = $(this).find('.checkout-panel__heading.js-accordion-header').text().trim();
        }
        else {
          evtLabel = $(this).text().trim();
        }
        if (clickSense) {
          trackEvent('checkout module open', 'ecommerce', 'module open', evtLabel);
        } else {
          trackEvent('checkout module close', 'ecommerce', 'module close', evtLabel);
        }
      });
      
      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.slick-prev,.slick-next', function () {
        var carouselName = $(this).closest('.viewcart-samples__content').siblings('.samples-panel__header').text().trim();
        trackEvent('checkout arrow click', 'ecommerce', 'arrow click', carouselName);
      });
      
      // Tacking samples and rewards view
      site.elcEvents.addListener('tealium:loaded', function () {
        if (document.URL.indexOf('cart') > -1) {
          $(window).on('scroll.viewportFired', function () {
            var moduleViewFire = document.querySelector('.js-viewcart-samples-products');
            var moduleView = document.querySelector('.js-eligible_boutique .checkout-panel__heading.js-accordion-header');
            var eventObj = eventObj || {};
            if (isInViewport(moduleViewFire) && !$(moduleViewFire).hasClass('js-viewport-fired-sample')) {
              $(moduleViewFire).addClass('js-viewport-fired-sample');
              eventObj.promo_pos = ['module'];
              eventObj.promo_name = [$(moduleViewFire).text().trim()];
              eventObj.promo_creative = ['View Cart Page'];
              eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
              site.track.evtView(eventObj);
            }
            else if (isInViewport(moduleView) && !$(moduleView).hasClass('js-viewport-fired')) {
              $(moduleView).addClass('js-viewport-fired');
              eventObj.promo_pos = ['module'];
              eventObj.promo_name = [document.querySelector('.js-eligible_boutique .checkout-panel__heading.js-accordion-header').innerText];
              eventObj.promo_creative = ['View Cart Page'];
              eventObj.promo_id = ['module-View Cart Page-' + eventObj.promo_name];
              site.track.evtView(eventObj);
            }
            if ($('.js-viewport-fired-sample').length > 0 && $('.js-viewport-fired').length > 0) {
              $(window).off('scroll.viewport');
            }
          });
        }
      });
      
      // Tracking need help links.
      $(document).on('click', '.needs-help-panel__content a', function () {
        trackEvent('click', 'ecommerce', 'cart', 'need-help - ' + $(this).text().trim());
      });
      
      //cart overlay close
      $(document).on('click', '.gnav-util--cart .icon--close,.js-gnav-util-icon .icon--close', function () {
        trackEvent('cart overlay', 'ecommerce', 'cart overlay close', 'close');
      });
      // Cart page tagging end.

      // Carbon Calculator Submit Button Tagging - Start.
      if ($('body').hasClass('viewcart')) {
        $(document).on('click', '.js-search-zip', function() {
          var eventObj = eventObj || {};
          Object.assign(eventObj, {
            event_name:'checkout_carbon_estimate',
            event_category: 'ecommerce',
            event_action: 'cloverly',
            event_label: 'click',
            promo_pos : undefined,
            promo_name : undefined,
            promo_creative : undefined,
            promo_id : undefined
          });
          site.track.evtLink(eventObj);
        });
      }
      // Carbon Calculator Submit Button Tagging - End.

      // Failed payment event tracking - Start.
      $(window).on('load', function () {
        if ($('body').is('#index')) {
          var $errorElem = $('.review-error-section').find("div[id='error_error.payment_provider_callback']");

          $errorElem.length > 0 ? trackEvent('payment error', 'ecommerce', 'payment', 'error') : '';
        }
      });
      // Failed payment event tracking - End.

      // MTA-4393 Aveda loyalty account page tagging - Start.
      if ($('div').hasClass('account-section')) {
        $(document).on('click', '.sidebar-menu__item a', function () {
          trackEvent('my account page', 'my account page', 'navigation click', $(this).text());
        });

        var rewardsSelector = [
          '.js-view-flash-rewards',
          '.js-tiers-tab',
          '.tier__products-product__description-toggle',
          '.aveda-plus-join-in__cta',
          '.aveda-plus-join-in__add-existing',
          '.aveda-plus-join-in__learn-more'
        ];

        $(document).on('click', rewardsSelector.join(', '), function () {
          trackEvent('my account page', 'my account page', 'cta click', $(this).text());
        });
      }

      $(document).on('previlege_pop_up_displayed', function (event, data) {
        var promoId = [];
        var promoName = [];
        var promoType = [];
        var overlayHeader = $('.js-pure-privilege-overlay').find('.pure-privilege-overlay__add_account').text();
        var templateName = data.template_name;

        if (typeof templateName !== 'undefined' && typeof overlayHeader !== 'undefined' && templateName.length > 0 && overlayHeader.length > 0) {
          overlayHeader = overlayHeader.trim();
          promoName.push(overlayHeader);
          promoType.push(templateName);
          promoId.push('module - ' + templateName + ' - ' + overlayHeader);
          eventObj.imp_promo_pos = ['module'];
          eventObj.imp_promo_creative = promoType;
          eventObj.imp_promo_name = promoName;
          eventObj.imp_promo_id = promoId;
          trackEvent('my account page', 'my account page', 'login overlay display', 'banner click | ' + overlayHeader);
        }
      });

      $(document).on('click', '.js-track-previlege-link-click', function () {
        var promoId = [];
        var promoName = [];
        var promoType = [];
        var $privilegeElem = $('.js-pure-privilege-overlay');
        var overlayHeader = $privilegeElem.find('.pure-privilege-overlay__add_account').text();
        var templateName = $privilegeElem.find('form').attr('name');

        if (typeof templateName !== 'undefined' && typeof overlayHeader !== 'undefined' && templateName.length > 0 && overlayHeader.length > 0) {
          overlayHeader = overlayHeader.trim();
          promoName.push(overlayHeader);
          promoType.push(templateName);
          promoId.push('module - ' + templateName + ' - ' + overlayHeader);
          eventObj.promo_pos = ['account'];
          eventObj.promo_creative = promoType;
          eventObj.promo_name = promoName;
          eventObj.promo_id = promoId;
          trackEvent('my account page', 'my account page', 'click', $(this).text());
        }
      });
      // Aveda loyalty account page tagging - End.

      // Inventory Visibility feature - Start
      $(document).on('DoorsInventory:query:success', function () {
        trackEvent('checkout_option', 'ecommerce', 'bopis zipcode search', 'find');
      });

      $(document).on('click', '.js-controls-edit', function () {
        trackEvent('checkout_option', 'ecommerce', 'bopis zipcode search', 'change');
      });
      // Inventory Visibility feature - End

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        // Trigger Alt image event only when alternate images are available
        if ($('body').hasClass('device-pc')) {
          trackAltImageTrackingClickPC();
        } else if ($('body').hasClass('device-mobile')) {
          trackAltImageTrackingMobile();
        }
      }

      // Track Alt image click - desktop
      function trackAltImageTrackingClickPC() {
        $(document).on('click', '.js-product-full-image-thumb, .slick-arrow', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails;
          var bgImageUrl = '';
          if ($targetElem.hasClass('slick-arrow')) {
            var $prodElem = $targetElem.closest('.carousel-controls');
            prodDetails = buildAltImageProductName($prodElem);
            bgImageUrl = $prodElem.siblings('.js-spp-carousel').find('.slick-list .slick-current img').attr('src');
          } else if ($targetElem.hasClass('js-product-full-image-thumb') && $('.js-product-full-image-thumb').length > 1) {
            prodDetails = buildAltImageProductName($targetElem);
            bgImageUrl = $targetElem.find('.js-product-full-thumb-img').attr('src');
          }
          if (bgImageUrl && bgImageUrl !== '') {
            altImageName = buildAltImageFileName(bgImageUrl);
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
          }
        });
      }

      // Track Alt image swipe - Mobile
      function trackAltImageTrackingMobile() {
        $(document).on('afterChange', '.product-full__image-carousel', function() {
          var $targetElem = $(this);
          var altImageName = '';
          var prodDetails = buildAltImageProductName($targetElem);
          var bgImageUrl = $targetElem.find('.slick-current img').attr('src');
          if (bgImageUrl) {
            altImageName = buildAltImageFileName(bgImageUrl);
          }
          trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'swipe');
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__name:first').text();
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
