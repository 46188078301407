import { IEventResultData } from '../cart/utils/helpers';
import { Controller } from '../utils/controller';

interface IFetch {
  endpoint: string;
  csrfToken?: string;
  errorEndpoint?: string;
  mode?: CartModeType;
  params?: IToggleCartModeEvent['detail'];
}

interface IAnalytics {
  drawerLabel: string;
  drawerAction: string;
  mode: CartModeType;
}

interface ICartItem {
  COLLECTION_ID: number;
  COLLECTION_SUBTYPE: string;
  product: { sku: { SKU_BASE_ID: string } };
  coll_info?: {
    COLLECTION_TYPE?: string;
  };
  'sku.SKU_BASE_ID': string;
}

interface IAtbResponse {
  ac_results: {
    result: {
      PREVIOUS_ITEM_QUANTITY: number;
      CARTITEM: {
        ITEM_QUANTITY: number;
        'sku.SKU_BASE_ID': string;
        CART_ID: number;
      };
      COLLECTION_ID?: number;
    };
  }[];
  coll_info?: {
    COLLECTION_ID?: number;
    COLLECTION_TYPE?: string;
  };
  trans_data?: {
    order: {
      items: {
        COLLECTION_ID: number;
        'sku.SKU_BASE_ID': string;
      }[];
      kits: {
        COLLECTION_ID: number;
        'sku.SKU_BASE_ID': string;
      }[];
    };
  };
  getItem(): ICartItem;
  getAllItems(): ICartItem[];
}

const FULL_HEIGHT = 100;

export const FULL_SCREEN_CLASS = 'cart-drawer__fullscreen';
export type CartModeType = 'modal' | 'overlay';
export type CartTimeout = undefined | number | ReturnType<typeof setTimeout>;

export interface IToastNotificationEvent {
  action?: 'decrease' | 'increase' | 'remove';
  newQty?: number;
  updatedSkuId?: string;
  updatedSkuName?: string;
}

export interface IToggleCartModeEvent {
  detail: {
    cartMode: CartModeType;
    atbResponseData: IAtbResponse;
    blockUpdate?: boolean;
    errorMessage?: string;
    collectionId?: number;
    skuId?: string[];
    offerCode?: string;
    quantity?: number;
    error?: boolean;
    html?: string;
    detail?: IToggleCartModeEvent['detail'];
  } & IToastNotificationEvent;
}

export interface ICloseEvent {
  detail: { delay: number };
  params?: { forceClose?: boolean };
}

export const cartDrawerTargets = [
  'alerts',
  'cartItemsList',
  'quantity',
  'drawerComponent',
  'errorModal',
  'handle',
  'toastNotifications',
  'overlayClose',
  'modalClose',
  'styles'
];

export const cartDrawerValues = {
  mode: 'overlay',
  dataEndpoint: '/cart/v3_0/drawer/',
  errorEndpoint: '/cart/v3_0/drawer/error',
  forceClose: false,
  isLoading: false,
  missingData: false,
  blockUpdate: false,
  stylesLoaded: false
};

export class CartDrawer extends Controller {
  alertsTargets: HTMLElement[];
  cartItemsListTarget: HTMLElement;
  quantityTarget: HTMLElement;
  drawerComponentTargets: HTMLElement[];
  errorModalTarget: HTMLElement;
  stylesTarget: HTMLElement;
  toastNotificationsTarget: HTMLElement;
  overlayCloseTarget: HTMLElement;
  modalCloseTarget: HTMLElement;
  handleTarget: HTMLElement;

  hasAlertsTarget: boolean;
  hasCartItemsListTarget: boolean;
  hasQuantityTarget: boolean;
  hasDrawerComponentTarget: boolean;
  hasErrorModalTarget: boolean;
  hasStylesTarget: boolean;
  hasToastNotificationsTarget: boolean;

  modeValue: CartModeType;
  dataEndpointValue: string;
  errorEndpointValue: string;
  forceCloseValue: boolean;
  isLoadingValue: boolean;
  missingDataValue: boolean;
  stylesLoadedValue: boolean;
  blockUpdate: boolean;
  errorMessage: string;
}

const processATBResponse = (params: IToggleCartModeEvent['detail']) => {
  const atbData = params.atbResponseData;

  if ('getItem' in atbData && 'getAllItems' in atbData) {
    params.atbResponseData['added_item'] = atbData.getItem() || {};
    params.atbResponseData['cart_items'] = atbData.getAllItems() || [];
  }

  return params;
};

export const fetchCartWithATB = async ({ endpoint, csrfToken, params }: IFetch) => {
  const processedParams = params ? processATBResponse(params) : params;

  return await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': csrfToken || ''
    },
    body: JSON.stringify(processedParams)
  })
    .then(async (response) => await response.text())
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Could not update cart', 'reason', error);
    });
};

export const fetchCart = (args: IFetch) => {
  const { endpoint, errorEndpoint, mode, params } = args;
  const {
    collectionId,
    offerCode,
    skuId = [],
    quantity = 1,
    error = false,
    errorMessage
  } = params || {};

  const shouldIncludeParams = mode === 'modal' && params && error === false;

  const idParams = offerCode ? `&offerCode=${offerCode}` : `&skuId=${skuId.toString()}`;
  const collectionParams = collectionId ? `&collectionId=${collectionId.toString()}` : '';

  const errorParams = error && errorMessage ? `&message=${encodeURIComponent(errorMessage)}` : '';
  const updateParams = `${idParams}&quantity=${quantity}${collectionParams}`;
  const requestParams = shouldIncludeParams ? updateParams : errorParams;

  const baseUrl = error ? errorEndpoint : endpoint;

  const url = `${baseUrl}?mode=${mode}${requestParams}`;

  return (
    fetch(url)
      .then((response) => response.text())
      // eslint-disable-next-line no-console
      .catch((error) => console.warn('Could not fetch cart drawer', { error, url }))
  );
};

export const cartDrawerAnalytics = ({ drawerAction, drawerLabel, mode }: IAnalytics) => {
  const cartDrawerType = mode === 'overlay' ? 'cart_dropdown' : 'cart_overlay';
  if (cartDrawerType) {
    const cartDrawerEvent = new CustomEvent('cart.drawer.clicked', {
      detail: {
        cartDrawerCta: drawerLabel,
        cartDrawerAction: drawerAction,
        cartDrawerCategory: cartDrawerType
      }
    });
    window.dispatchEvent(cartDrawerEvent);
  }
};

export const handlePageScroll = (action: 'enable' | 'disable') => {
  const hasVulcanStyles = window._platform === 'vulcan';
  const scrollClass = hasVulcanStyles ? 'disable-scroll--modernized' : 'disable-scroll';
  const removeAdditionalClass = 'active-gnav';

  if (action === 'disable') {
    document.body.classList.add(scrollClass);
    document.body.classList.remove(removeAdditionalClass);
    document.documentElement.classList.add(scrollClass);
  } else {
    document.body.classList.remove(scrollClass, removeAdditionalClass);
    document.documentElement.classList.remove(scrollClass);
  }
};

// Drag methods
export const setDrawerHeight = (modal: HTMLElement, value: number) => {
  const newHeight = Math.max(0, Math.min(FULL_HEIGHT, value));

  if (newHeight === FULL_HEIGHT) {
    modal.classList.add(FULL_SCREEN_CLASS);
  } else {
    modal.style.height = `${newHeight}dvh`;
    modal.classList.remove(FULL_SCREEN_CLASS);
  }

  return newHeight;
};

export const getDrawerHeight = (modal: HTMLElement) =>
  (modal.offsetHeight / window.innerHeight) * 100;

export const clearDrawerHeight = (modal: HTMLElement) => {
  modal.style.height = '';
  modal.classList.remove(FULL_SCREEN_CLASS);
};

export const touchPosition = (event: TouchEvent & DragEvent) => {
  const data = event.touches ? event.touches[0] : event;
  return data.pageY;
};

export const setGlobalStyles = (element: HTMLElement) => {
  const stylesInRoot = document.head.querySelector(
    '[data-cart-drawer-target="styles"][data-loaded="true"]'
  );

  if (!stylesInRoot) {
    const updatedElement = element.cloneNode(true) as HTMLElement;
    updatedElement.setAttribute('data-loaded', 'true');
    document.head.appendChild(updatedElement);
    element.remove();

    return true;
  }

  element.remove();
  return !!stylesInRoot;
};

export const sanitizePayload = (payload: IEventResultData) => {
  const sanitizedPayload = { ...payload };

  if ('dataLayer' in payload) {
    delete sanitizedPayload.dataLayer;
  }

  if ('trans_data' in payload) {
    delete sanitizedPayload.trans_data;
  }

  return sanitizedPayload;
};
